import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
import ExternalLink from '@components/ExternalLink/ExternalLink';
import * as styles from '@layouts/HomeLayout.module.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Pradux`}</h1>
    <p><em parentName="p">{`Note: this page is under construction. Please check back soon.`}</em></p>
    <br />
    <div className={styles.workDetails}>
  Head of Engineering
  <br />
  2012 - 2016, New York, NY [WeWork Labs]
  <br />
  2011 - 2012, Cambridge, MA [Dogpatch Labs]
    </div>
    <ul>
      <li parentName="ul">{`Shop 100k products as seen on 100 tv shows`}</li>
      <li parentName="ul">{`Built up the largest database of products featured on television`}</li>
      <li parentName="ul">{`Photos annotated with interactive "shop dots"`}</li>
      <li parentName="ul">{`Platform to create and browse tagged photos. Our photos featured "shop dots" on hover, allowing you to buy the items you see.`}</li>
      <li parentName="ul">{`All photos on Pradux are embeddable anywhere on the web`}</li>
      <li parentName="ul">{`Accepted into Dogpatch Labs, a startup incubator in Boston, MA.`}</li>
    </ul>
    <h2>{`Press coverage`}</h2>
    <ul>
      <li parentName="ul">{`As seen on TV: Pradux lets you buy clothes from your favorite shows `}<ExternalLink href="https://venturebeat.com/2014/01/16/as-seen-on-tv-pradux-lets-you-buy-clothes-from-your-favorite-shows/" mdxType="ExternalLink">{`venturebeat.com`}</ExternalLink></li>
      <li parentName="ul">{`Want That Olivia Pope Look? Pradux Let’s You Shop Your Favorite Shows `}<ExternalLink href="https://www.fastcompany.com/3025186/want-that-olivia-pope-look-pradux-lets-you-shop-your-favorite-shows" mdxType="ExternalLink">{`fastcompany.com`}</ExternalLink></li>
    </ul>
    {
      /*
      ^ Pradux makes identifying, and buying, that outfit you saw on Scandal, or (shudder) The Real Housewives, an easier process.
      TO ADD:
      - Image of shazam integration
      - Image of tumblr integration?
      -
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      